<template>
  <Header
    ref="headerRef"
    type="INCOME_STATEMENT"
    :title="t('menus.Reports')"
    :showRegenerateButton="false"
    :updatedOn="updatedTime ?? ''"
  />
  <ReportsMenus
    ref="ReportsMenusRef"
    :type="exportProgressStatusType.incomeStatement.reportValue"
    :params="{ ...params, groupList, subGroupList, listType, listTypeOptions }"
    :title="t('project.nav.incomeStatement')"
    @onChangDateTime="onChangDateTime"
    @onChangGroup="onChangGroup"
    @onChangSubGroup="onChangSubGroup"
    @onChangListType="onChangListType"
  />
  <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.incomeStatement" :isMarginTop="true" />
  <div v-loading="loading" class="elv-reports-income-statement-content">
    <FinancialStatement
      ref="financialStatementRef"
      financial-type="income-statement"
      :params="requestParams"
      :isShowArrow="group !== '0'"
      :isShowRegeneratedInfo="ReportsMenusRef?.isShowRegeneratedInfo"
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import { useEntityStore } from '@/stores/modules/entity'
import FinancialStatement from '../components/FinancialStatement.vue'
import ExportDataTaskHistoryInfo from '../components/ExportDataTaskHistoryInfo.vue'
import { exportProgressStatusType } from '@/config/global'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const entityStore = useEntityStore()
const { loading } = storeToRefs(reportStore)
const { incomeStatementGroup } = reactive(entityStore.entityDetail)

const group = ref(incomeStatementGroup?.group?.auxiliaryTypeId || '0')
const subGroup = ref(incomeStatementGroup?.subGroup?.auxiliaryTypeId || '0')

const listType = ref('COLLAPSED')
const listTypeOptions = ref([
  {
    value: 'COLLAPSED',
    label: t('common.collapseAll')
  },
  {
    value: 'EXPANDED',
    label: t('common.expandAll')
  }
])

const headerRef = useTemplateRef('headerRef')
const ReportsMenusRef = useTemplateRef<InstanceType<typeof ReportsMenus>>('ReportsMenusRef')
const financialStatementRef = useTemplateRef<InstanceType<typeof FinancialStatement>>('financialStatementRef')
const updatedTime = computed(() => {
  return reportStore.financialData.updatedOn
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const params = computed(() => {
  return {
    period:
      ReportsMenusRef.value?.periodValue === 'date' ? 'DAY' : ReportsMenusRef.value?.periodValue.toLocaleUpperCase(),
    dateRange: ReportsMenusRef.value?.dateValue,
    group: group.value,
    subGroup: subGroup.value
  }
})

const requestParams = computed(() => {
  return {
    period:
      ReportsMenusRef.value?.periodValue === 'date' ? 'DAY' : ReportsMenusRef.value?.periodValue.toLocaleUpperCase(),
    dateRange: ReportsMenusRef.value?.dateValue,
    group: {
      auxiliaryTypeId: group.value
    },
    subGroup: {
      auxiliaryTypeId: subGroup.value
    }
  }
})

const groupList = computed(() => {
  const list = [
    {
      auxiliaryTypeId: '0',
      name: t('project.none')
    },
    ...reportStore.auxiliaryTypeList
  ]

  return list.map((item: any) => {
    if (subGroup.value === '0') {
      return { ...item, disabled: false }
    }
    return { ...item, disabled: subGroup.value === item.auxiliaryTypeId }
  })
})

const subGroupList = computed(() => {
  const list = [
    {
      auxiliaryTypeId: '0',
      name: t('project.none')
    },
    ...reportStore.auxiliaryTypeList
  ]

  return list.map((item: any) => {
    if (group.value === '0') {
      return { ...item, disabled: true }
    }
    return { ...item, disabled: group.value === item.auxiliaryTypeId }
  })
})

const onChangParams = async (data: object) => {
  await reportStore.financialsDataInit(entityId.value, 'income-statement', data)
}

const onChangGroup = async (value: string) => {
  if (group.value === value) {
    group.value = '0'
  } else {
    group.value = value
  }
  listType.value = 'COLLAPSED'

  if (value === '0') {
    subGroup.value = '0'
  }

  await onChangParams(requestParams.value)
}

const onChangSubGroup = async (value: string) => {
  if (subGroup.value === value) {
    subGroup.value = '0'
  } else {
    subGroup.value = value
  }
  listType.value = 'COLLAPSED'
  await onChangParams(requestParams.value)
}

const onChangListType = async (value: string) => {
  listType.value = value
  if (value === 'EXPANDED') {
    financialStatementRef.value?.handleCollapseAll()
  } else {
    financialStatementRef.value?.handleExpandAll()
  }
}

const onChangDateTime = async (data: object) => {
  listType.value = 'COLLAPSED'
  await onChangParams({ ...requestParams.value, ...data })
}
</script>
<style lang="scss" scoped>
.elv-reports-income-statement-content {
  min-height: 0px;
  flex: 1;
  position: relative;
}
</style>
